<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-tabs>
          <v-tab>Anggota</v-tab>
          <v-tab>Pekerjaan</v-tab>
          <v-tab>Pendidikan</v-tab>
          <v-tab>Peminatan</v-tab>
          <v-tab>Event Diikuti</v-tab>
          <v-tab-item>
            <v-card>
              <v-card-title> Edit Anggota </v-card-title>
              <v-fab-transition>
                <v-btn
                  v-if="isEditable == 0"
                  class="mt-8 mr-4"
                  color="pink"
                  dark
                  absolute
                  top
                  right
                  fab
                  @click="changeEditable"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-fab-transition>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4 pt-4"
              >
                <v-row dense>
                  <v-col cols="12" sm="6" md="6" class="pr-4">
                    <v-sheet color="white" elevation="2">
                      <v-responsive :aspect-ratio="16 / 9">
                        <v-img
                          :key="fotoKey"
                          :src="userData.FotoUrl"
                          contain
                        ></v-img>
                      </v-responsive>
                    </v-sheet>
                    <v-file-input
                      v-model="userData.file_foto"
                      accept="image/*"
                      prepend-icon="mdi-image"
                      show-size
                      label="Foto"
                      :disabled="isEditable == 0"
                      @change="loadImage"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="userData.nira"
                          label="NIRA"
                          outlined
                          dense
                          :rules="userData.niraRules"
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-combobox
                          v-model="userData.selectedKomisariat"
                          label="Komisariat"
                          clearable
                          dense
                          outlined
                          :items="items_komisariat"
                          item-text="nama"
                          :readonly="isEditable == 0"
                          :disabled="isAdminKomisariat"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="userData.nama"
                          label="Nama"
                          outlined
                          dense
                          :rules="userData.namaRules"
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          label="No Anggota"
                          outlined
                          dense
                          v-model="userData.no_anggota"
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                    </v-row> -->
                    <v-row dense>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="userData.phone"
                          label="No Telepon"
                          outlined
                          dense
                          :rules="userData.phoneRules"
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="userData.email"
                          label="Email"
                          outlined
                          dense
                          :rules="userData.emailRules"
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="userData.str"
                          label="STR"
                          outlined
                          dense
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="userData.masa_berlaku_str"
                          label="Masa Berlaku STR"
                          dense
                          readonly
                        ></v-text-field>
                      </v-col>
                      <!-- <v-col cols="12" sm="6" md="6">
                        <v-combobox
                          label="Profesi"
                          clearable
                          dense
                          outlined
                          v-model="userData.selectedProfesi"
                          :items="items_profesi"
                          item-text="value"
                          :readonly="isEditable == 0"
                        ></v-combobox>
                      </v-col> -->
                    </v-row>
                    <v-row dense> </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <h5>Akun Satu Sehat SDMK</h5>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="userData.email_satu_sehat"
                      label="Email"
                      outlined
                      dense
                      :readonly="isEditable == 0"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="userData.password_satu_sehat"
                      label="Password"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      outlined
                      dense
                      :readonly="isEditable == 0"
                      @click:append="show1 = !show1"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <h5>Personal Information</h5>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-row dense>
                      <v-col cols="12" sm="6" md="6">
                        <v-combobox
                          v-model="userData.selectedJenisKelamin"
                          label="Jenis Kelamin"
                          clearable
                          dense
                          outlined
                          :items="items_jenis_kelamin"
                          item-text="value"
                          :readonly="isEditable == 0"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-combobox
                          v-model="userData.selectedAgama"
                          label="Agama"
                          clearable
                          dense
                          outlined
                          :items="items_agama"
                          item-text="value"
                          :readonly="isEditable == 0"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="userData.no_ktp"
                          label="No KTP"
                          outlined
                          dense
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="userData.tempat_lahir"
                          label="Tempat Lahir"
                          outlined
                          dense
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="userData.tanggal_lahir"
                          label="Tanggal Lahir"
                          type="date"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="6" md="6">
                        <v-combobox
                          v-model="userData.selectedPropinsi"
                          label="Propinsi"
                          clearable
                          dense
                          outlined
                          :items="items_propinsi"
                          item-text="name"
                          :readonly="isEditable == 0"
                          @change="changePropinsi"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-combobox
                          v-model="userData.selectedKabupaten"
                          label="Kabupaten/Kota"
                          clearable
                          dense
                          outlined
                          :items="items_kabupaten"
                          item-text="name"
                          :readonly="isEditable == 0"
                          @click="getListKabupaten"
                          @change="changeKabupaten"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-combobox
                          v-model="userData.selectedKecamatan"
                          label="Kecamatan"
                          clearable
                          dense
                          outlined
                          :items="items_kecamatan"
                          item-text="name"
                          :readonly="isEditable == 0"
                          @click="getListKecamatan"
                          @change="changeKecamatan"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-combobox
                          v-model="userData.selectedKelurahan"
                          label="Kelurahan"
                          clearable
                          dense
                          outlined
                          :items="items_kelurahan"
                          item-text="name"
                          :readonly="isEditable == 0"
                          @click="getListKelurahan"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="userData.address"
                          label="Alamat Lengkap"
                          outlined
                          dense
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6"> </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-end">
                    <v-btn
                      v-if="isEditable == 1"
                      style="margin-right: 10px"
                      @click="doBatal"
                      >Batal</v-btn
                    >
                    <v-btn
                      v-if="isEditable == 1"
                      dark
                      color="primary"
                      type="button"
                      @click="doSubmit"
                      >Simpan</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <PekerjaanComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <PendidikanComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <PeminatanComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <EventDiikutiComponent :id="id" />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import NakesService from "../services/NakesService";
import FaskesService from "../services/FaskesService";
import KomisariatService from "../services/KomisariatService";
import * as moment from "moment";

import PekerjaanComponent from "./user/PekerjaanUser.vue";
import PendidikanComponent from "./user/PendidikanUser.vue";
import PeminatanComponent from "./user/PeminatanUser.vue";
import EventDiikutiComponent from "./user/EventDiikuti.vue";
import store from "../store/index";

export default {
  name: "NakesEdit",
  components: {
    PekerjaanComponent,
    PendidikanComponent,
    PeminatanComponent,
    EventDiikutiComponent,
  },
  props: {
    id: {
      type: String,
      default: "0",
    },
  },
  data: () => ({
    show1: false,
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data Anggota",
        disabled: false,
        exact: true,
        to: { name: "Nakes" },
      },
      { text: "Edit Anggota", disabled: true },
    ],
    valid: true,
    userData: {
      FotoUrl: "",
      file_foto: null,
      nira: "",
      nama: "",
      email: "",
      phone: "",
      profesi_id: "",
      str: "",
      no_ktp: "",
      tempat_lahir: "",
      selectedProfesi: "",
      selectedCommunity: "",
      selectedPropinsi: "",
      selectedKabupaten: "",
      selectedKecamatan: "",
      selectedKelurahan: "",
      selectedKomisariat: "",
      masa_berlaku_str: "",
      no_anggota: "",
      tanggal_lahir: "",
      address: "",
      masa_berlaku: "",
      selectedJenisKelamin: "",
      selectedAgama: "",
      email_satu_sehat: "",
      password_satu_sehat: "",
      niraRules: [(v) => !!v || "NIRA harus diisi"],
      namaRules: [(v) => !!v || "Nama harus diisi"],
      phoneRules: [(v) => !!v || "No Telepon harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
      noAnggotaRules: [(v) => !!v || "No Anggota harus diisi"],
    },
    items_profesi: [],
    items_community: [],
    items_propinsi: [],
    items_kabupaten: [],
    items_kecamatan: [],
    items_kelurahan: [],
    items_jenis_kelamin: [],
    items_agama: [],
    items_komisariat: [],
    fotoKey: 0,
    searchNakes: "",
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },
  }),
  computed: {
    getDpdPengurusId() {
      return store.getters.getUser.dpd_id;
    },
    getKomisariatId() {
      return store.getters.getUser.komisariat_id;
    },
    isAdminKomisariat() {
      if (store.getters.getUser.komisariat_id) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.getNakesFromApi();
      },
      deep: true,
    },
  },
  mounted: async function () {
    this.getListPropinsi();
    this.getListKabupaten();
    this.getListKecamatan();
    this.getListKelurahan();
    this.getListProfesi();
    this.getListCommunityAll();
    this.getListJenisKelamin();
    this.getListAgama();
    this.getListKomisariat();
    this.getUser();
  },
  methods: {
    async getListKomisariat() {
      var dpdId = "";

      if (this.getDpdPengurusId) {
        dpdId = this.getDpdPengurusId;
      }

      if (dpdId) {
        KomisariatService.komisariatListAllFilter(dpdId)
          .then((res) => {
            this.items_komisariat = res.data.list_data;

            if (this.getKomisariatId) {
              this.items_komisariat.forEach((entry) => {
                if (entry.id == this.getKomisariatId) {
                  this.userData.selectedKomisariat = entry;
                }
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        KomisariatService.komisariatListAll()
          .then((res) => {
            this.items_komisariat = res.data.list_data;

            if (this.getKomisariatId) {
              this.items_komisariat.forEach((entry) => {
                if (entry.id == this.getKomisariatId) {
                  this.userData.selectedKomisariat = entry;
                }
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    getNakesFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      FaskesService.faskesList(page, itemsPerPage, this.searchNakes)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    changeEditable: function () {
      this.isEditable = 1;
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.userData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.userData.FotoUrl = "";
        this.userData.file_foto = null;
      }
    },

    getUser() {
      NakesService.getUser(this.id)
        .then((res) => {
          console.log(res.data.user);
          this.userData.nira = res.data.user.nira;
          this.userData.nama = res.data.user.nama;
          this.userData.no_anggota = res.data.user.no_anggota;
          this.userData.phone = res.data.user.phone;
          this.userData.email = res.data.user.email;
          this.userData.str = res.data.user.str;
          this.userData.no_ktp = res.data.user.no_ktp;
          this.userData.tempat_lahir = res.data.user.tempat_lahir;
          this.userData.address = res.data.user.address;

          this.userData.email_satu_sehat = res.data.user.email_satu_sehat;
          this.userData.password_satu_sehat = res.data.user.password_satu_sehat;

          this.userData.masa_berlaku_str = "SELAMANYA";

          if (res.data.user.tanggal_lahir) {
            this.userData.tanggal_lahir = moment(
              res.data.user.tanggal_lahir,
            ).format("YYYY-MM-DD");
          }

          if (res.data.user.masa_berlaku) {
            this.userData.masa_berlaku = moment(
              res.data.user.masa_berlaku,
            ).format("YYYY-MM-DD");
          }

          if (res.data.user.jenis_kelamin_id) {
            this.items_jenis_kelamin.forEach((entry) => {
              if (entry.code == res.data.user.jenis_kelamin_id) {
                this.userData.selectedJenisKelamin = entry;
              }
            });
          }

          if (res.data.user.agama_id) {
            this.items_agama.forEach((entry) => {
              if (entry.code == res.data.user.agama_id) {
                this.userData.selectedAgama = entry;
              }
            });
          }

          if (res.data.user.profesi_id) {
            this.items_profesi.forEach((entry) => {
              if (entry.code == res.data.user.profesi_id) {
                this.userData.selectedProfesi = entry;
              }
            });
          }

          if (res.data.user.community_id) {
            this.items_community.forEach((entry) => {
              if (entry.id == res.data.user.community_id) {
                this.userData.selectedCommunity = entry;
              }
            });
          }

          if (res.data.user.propinsi_id) {
            this.items_propinsi.forEach((entry) => {
              if (entry.id == res.data.user.propinsi_id) {
                this.userData.selectedPropinsi = entry;
              }
            });

            this.getListKabupatenAll(
              res.data.user.propinsi_id,
              res.data.user.kabupaten_id,
            );

            this.getListKecamatanAll(
              res.data.user.kabupaten_id,
              res.data.user.kecamatan_id,
            );

            this.getListKelurahanAll(
              res.data.user.kecamatan_id,
              res.data.user.kelurahan_id,
            );
          }

          if (res.data.user.komisariat_id) {
            this.items_komisariat.forEach((entry) => {
              if (entry.id == res.data.user.komisariat_id) {
                this.userData.selectedKomisariat = entry;
              }
            });
          }

          this.userData.FotoUrl = res.data.user.photo;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListProfesi() {
      NakesService.profesiList()
        .then((res) => {
          this.items_profesi = res.data.list_data;

          this.getUser();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListJenisKelamin() {
      NakesService.jenisKelaminList()
        .then((res) => {
          this.items_jenis_kelamin = res.data.list_data;

          this.getUser();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListAgama() {
      NakesService.agamaList()
        .then((res) => {
          this.items_agama = res.data.list_data;

          this.getUser();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async changePropinsi() {
      this.userData.selectedKabupaten = null;
      this.userData.selectedKecamatan = null;
      this.userData.selectedKelurahan = null;
    },

    async changeKabupaten() {
      this.userData.selectedKecamatan = null;
      this.userData.selectedKelurahan = null;
    },

    async changeKecamatan() {
      this.userData.selectedKelurahan = null;
    },

    async getListKabupaten() {
      NakesService.kabupatenList(this.userData.selectedPropinsi.id)
        .then((res) => {
          this.items_kabupaten = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKecamatan() {
      NakesService.kecamatanList(this.userData.selectedKabupaten.id)
        .then((res) => {
          this.items_kecamatan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKelurahan() {
      NakesService.kelurahanList(this.userData.selectedKecamatan.id)
        .then((res) => {
          this.items_kelurahan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKabupatenAll(propinsiId, kabupatenId) {
      NakesService.kabupatenList(propinsiId)
        .then((res) => {
          this.items_kabupaten = res.data.list_data;
          if (kabupatenId) {
            this.items_kabupaten.forEach((entry) => {
              if (entry.id == kabupatenId) {
                this.userData.selectedKabupaten = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKecamatanAll(kabupatenId, kecamatanId) {
      NakesService.kecamatanList(kabupatenId)
        .then((res) => {
          this.items_kecamatan = res.data.list_data;
          if (kecamatanId) {
            this.items_kecamatan.forEach((entry) => {
              if (entry.id == kecamatanId) {
                this.userData.selectedKecamatan = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKelurahanAll(kecamatanId, kelurahanId) {
      NakesService.kelurahanList(kecamatanId)
        .then((res) => {
          this.items_kelurahan = res.data.list_data;
          if (kelurahanId) {
            this.items_kelurahan.forEach((entry) => {
              if (entry.id == kelurahanId) {
                this.userData.selectedKelurahan = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListCommunityAll() {
      NakesService.communityListAll()
        .then((res) => {
          this.items_community = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.go();
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      NakesService.update(this.id, this.userData, this.userData.file_foto)
        .then((res) => {
          console.log(res);
          this.isEditable = 0;
          this.$toast.success("Update Nakes berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Update Nakes gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
  },
};
</script>

<style scoped></style>
