<template>
  <v-main>
    <v-container fluid>
      <v-progress-linear
        v-if="isProgressBarShow"
        indeterminate
        color="green"
      ></v-progress-linear>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <v-card>
        <v-card-title>
          Akun Satu Sehat SDMK
          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Akun Satu Sehat</span>
              </v-card-title>
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  class="pl-4 pr-4"
                >
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="currentData.email_satu_sehat"
                          label="Email"
                          required
                          :rules="currentData.emailRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="currentData.password_satu_sehat"
                          dense
                          label="Password"
                          required
                          :rules="currentData.passwordRules"
                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show1 ? 'text' : 'password'"
                          @click:append="show1 = !show1"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-row class="mx-4 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              outlined
              dense
              hide-details
              @keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedStatus"
              label="Status Akun"
              dense
              outlined
              clearable
              :items="items_status"
              item-text="nama"
              @change="changeStatus"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedDpw"
              label="Dewan Pengurus Wilayah"
              clearable
              dense
              outlined
              :items="items_dpw"
              item-text="nama"
              :disabled="isDpwPengurus"
              @change="changeDpw"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedDpd"
              label="Dewan Pengurus Daerah"
              clearable
              dense
              outlined
              :items="items_dpd"
              item-text="nama"
              :disabled="isDpdPengurus"
              @change="changeDpd"
              @click="getListDpd"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedKomisariat"
              label="Komisariat"
              clearable
              dense
              outlined
              :items="items_komisariat"
              item-text="nama"
              :disabled="isAdminKomisariat"
              @change="changeKomisariat"
              @click="getListKomisariat"
            ></v-combobox>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template #[`item.password_satu_sehat`]="{ item }">
            <div v-if="item.password_satu_sehat">******</div>
          </template>

          <template #[`item.is_aktif`]="{ item }">
            <v-chip :color="getColor(item.is_aktif, item.data_status)" dark>
              {{ getStatusKeanggotaan(item.is_aktif, item.data_status) }}
            </v-chip>
          </template>
          <template #[`item.actions`]="{ item }">
            <div class="d-flex flex-row">
              <v-icon medium class="mr-3" @click="editItem(item)">
                mdi-pencil
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import NakesService from "../services/NakesService";
// import MasterService from "../services/MasterService";
import DPWService from "../services/DPWService";
import DPDService from "../services/DPDService";
import KomisariatService from "../services/KomisariatService";
import store from "../store/index";

export default {
  data: () => ({
    show1: false,
    valid: true,
    isProgressBarShow: false,
    dialog: false,
    breadcrumbs: [
      {
        text: "Akun Satu Sehat",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",

    selectedDpw: "",
    selectedDpd: "",
    selectedKomisariat: "",
    items_komisariat: [],
    items_dpw: [],
    items_dpd: [],

    // selectedPropinsi: "",
    selectedTahun: "",
    selectedStatus: "",
    // items_propinsi: [],
    items_tahun: [],
    // items_status_pegawai: [],
    items_status: [
      {
        id: 1,
        nama: "Sudah Mengisi",
      },
      { id: 0, nama: "Belum Mengisi" },
    ],
    currentData: {
      id: null,
      email_satu_sehat: "",
      password_satu_sehat: "",
      emailRules: [(v) => !!v || "Email harus diisi"],
      passwordRules: [(v) => !!v || "Password harus diisi"],
    },

    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "No Telp", value: "phone", sortable: false },
      { text: "NIK", value: "no_ktp", sortable: false },
      { text: "STR", value: "str", sortable: false },
      { text: "DPW", value: "dpw_nama", sortable: false },
      { text: "DPD", value: "dpd_nama", sortable: false },
      { text: "Komisariat", value: "komisariat_nama", sortable: false },
      {
        text: "Email Satu Sehat",
        value: "email_satu_sehat",
        sortable: false,
      },
      {
        text: "Password Satu Sehat",
        value: "password_satu_sehat",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
  }),
  computed: {
    getDpwPengurusId() {
      return store.getters.getUser.dpw_id;
    },
    getDpdPengurusId() {
      return store.getters.getUser.dpd_id;
    },
    isDpwPengurus() {
      if (store.getters.getUser.dpw_id) {
        return true;
      } else {
        return false;
      }
    },
    isDpdPengurus() {
      if (store.getters.getUser.dpd_id) {
        return true;
      } else {
        return false;
      }
    },
    getKomisariatId() {
      return store.getters.getUser.komisariat_id;
    },
    isAdminKomisariat() {
      if (store.getters.getUser.komisariat_id) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    for (var i = new Date().getFullYear(); i >= 1990; i--) {
      this.items_tahun.push(i);
    }

    this.selectedTahun = new Date().getFullYear();

    // this.getListPropinsi();
    // this.getListGelar();
    this.getListDpw();
    // this.getListStatusPegawai();
  },
  methods: {
    changeDpw() {
      this.selectedDpd = null;
      this.selectedKomisariat = null;
      this.getDataFromApi();
    },

    changeDpd() {
      this.selectedKomisariat = null;
      this.getDataFromApi();
    },

    changeKomisariat() {
      this.getDataFromApi();
    },

    async getListDpw() {
      DPWService.dpwListAll()
        .then((res) => {
          this.items_dpw = res.data.list_data;

          if (this.getDpwPengurusId) {
            this.items_dpw.forEach((entry) => {
              if (entry.id == this.getDpwPengurusId) {
                this.selectedDpw = entry;

                if (this.getDpdPengurusId) {
                  this.getListDpd();
                } else {
                  this.getDataFromApi();
                }
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListDpd() {
      var dpwId = "";

      if (this.selectedDpw) {
        dpwId = this.selectedDpw.id;
      }

      DPDService.dpdListAll(dpwId)
        .then((res) => {
          this.items_dpd = res.data.list_data;

          if (this.getDpdPengurusId) {
            this.items_dpd.forEach((entry) => {
              if (entry.id == this.getDpdPengurusId) {
                this.selectedDpd = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKomisariat() {
      KomisariatService.komisariatListAllFilter(this.selectedDpd.id)
        .then((res) => {
          this.items_komisariat = res.data.list_data;

          if (this.getKomisariatId) {
            this.items_komisariat.forEach((entry) => {
              if (entry.id == this.getKomisariatId) {
                this.selectedKomisariat = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // async getListStatusPegawai() {
    //   MasterService.statusPekerjaanList()
    //     .then((res) => {
    //       this.items_status_pegawai = res.data.list_data;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    getColor(dataStatus, status) {
      if (status == 1) {
        switch (dataStatus) {
          case 1:
            return "blue";
          default:
            return "red";
        }
      } else {
        return "";
      }
    },
    getStatusKeanggotaan(id, status) {
      if (status == 1) {
        if (id == 1) {
          return "Aktif";
        } else {
          return "Tidak Aktif";
        }
      } else {
        if (status == 1) {
          return "Aktif";
        } else if (status == 2) {
          return "Menunggu Konfirmasi Penghapusan Anggota";
        } else if (status == 3) {
          return "Tidak Aktif";
        } else if (status == 4) {
          return "Menunggu Konfirmasi Perubahan Anumerta";
        } else if (status == 5) {
          return "Anumerta";
        }
      }
    },
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      var komisariat = "";

      if (this.selectedKomisariat) {
        komisariat = this.selectedKomisariat.id;
      }

      var dpw = "";
      var dpd = "";

      if (this.selectedDpw) {
        dpw = this.selectedDpw.id;
      }

      if (this.selectedDpd) {
        dpd = this.selectedDpd.id;
      }

      var statusAkun = "";

      if (this.selectedStatus) {
        statusAkun = this.selectedStatus.id;
      }

      this.loading = true;
      NakesService.nakesList(
        page,
        itemsPerPage,
        this.search,
        komisariat,
        dpw,
        dpd,
        statusAkun,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      // this.dialog = true;
      this.currentData.email_satu_sehat = this.editedItem.email_satu_sehat;
      this.currentData.password_satu_sehat =
        this.editedItem.password_satu_sehat;
      this.currentData.id = this.editedItem.id;
      this.dialog = true;
    },

    doSave() {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      NakesService.updateAkunSatuSehat(this.currentData)
        .then((res) => {
          console.log(res);
          this.isEditable = 0;
          this.dialog = false;
          this.$toast.success("Update Akun Satu Sehat Berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Update Akun Satu Sehat gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    // async getListPropinsi() {
    //   NakesService.propinsiList()
    //     .then((res) => {
    //       this.items_propinsi = res.data.list_data;

    //       if (this.getCabangPengurusId) {
    //         this.items_propinsi.forEach((entry) => {
    //           if (entry.id == this.getCabangPengurusId) {
    //             this.selectedPropinsi = entry;
    //             this.getDataFromApi();
    //           }
    //         });
    //       } else {
    //         this.getDataFromApi();
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    async changeStatus() {
      this.getDataFromApi();
    },
  },
};
</script>
